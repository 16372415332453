import styled from "@emotion/styled";
import { useEffect, useState } from "react";

import { loadEnv } from "@smart/bridge-env-dom";
import { PaymentResponse } from "@smart/bridge-types-basic";
import { Loading } from "@smart/itops-components-dom";
import { useAsync } from "@smart/itops-hooks-dom";
import { Icon } from "@smart/itops-icons-dom";
import { backoff, Env, Region } from "@smart/itops-utils-basic";

import { ErrorDisplay } from "./error";
import { Label } from "./label";
import { FieldProps, FieldWrapper } from "./wrapper";
import { LoadResponses } from "../../types";

const paymentPortalOrigins = {
  aus: {
    local: "https://test.smartsb.dev",
    dev: "https://devpayments-billing.smokeball.com.au",
    staging: "https://rcpayments-billing.smokeball.com.au",
    live: "https://payments-billing.smokeball.com.au",
  },
  nova: {
    local: "https://test.smartsb.dev",
    dev: "https://devpayments-billing.smokeball.com",
    staging: "https://rcpayments-billing.smokeball.com",
    live: "https://payments-billing.smokeball.com",
  },
  lon: {
    local: "https://test.smartsb.dev",
    dev: "https://devpayments-billing.smokeball.co.uk",
    staging: "https://rcpayments-billing.smokeball.co.uk",
    live: "https://payments-billing.smokeball.co.uk",
  },
} as const;

type ChargeDataMessage = {
  messageType: "CHARGE_DATA_READY";
  paymentRequestId: string;
  payload: {
    chargeRequest: PaymentResponse["chargeRequest"];
  };
};

const PaymentPortalWrapper = styled.div<{ hasError: boolean }>`
  border: ${(props) =>
    props.hasError ? `solid 1px ${props.theme.scheme.red.r100}` : "none"};
  border-radius: ${(props) => props.theme.baseUnit * 0.8}rem;
  box-shadow: ${(props) =>
    props.hasError ? `0 0 0 3px ${props.theme.scheme.red.r10}` : "none"};
  overflow: hidden;

  .payment-saved {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    min-height: 20rem;
    font-size: ${(props) => props.theme.fontSize.base};
  }
`;

const PaymentPortal = styled.iframe`
  width: 100%;
  min-height: 130rem;
  border: none;
`;

export const PaymentField = ({
  field,
  index,
  value,
  error,
  loading,
  disabled,
  hideLabel,
  onChange,
  onBlur,
  loadResponses,
}: FieldProps<HTMLDivElement, PaymentResponse> & {
  loadResponses: LoadResponses;
}) => {
  const [paymentResponse, setPaymentResponse] = useState<
    PaymentResponse | undefined
  >(value);

  const isSetupIncomplete =
    !field.payment?.amountInCents || !field.payment.bankAccount;

  useAsync(async () => {
    if (paymentResponse?.paymentPortalUrl || isSetupIncomplete) return;

    const getPaymentPortalUrl = (
      responses: Awaited<ReturnType<LoadResponses>>,
    ) => responses?.[field.uri]?.value?.paymentPortalUrl;

    const responses = await backoff({
      run: loadResponses,
      test: (found) => !!getPaymentPortalUrl(found),
      attempts: 5,
      base: 1000,
      shouldCatch: true,
    })();

    if (getPaymentPortalUrl(responses)) {
      setPaymentResponse(responses?.[field.uri].value);
    }
  }, [paymentResponse?.paymentPortalUrl]);

  useEffect(() => {
    const region = loadEnv("Region") as Region;
    const env = loadEnv("Env") as Env;
    const listener = (event: MessageEvent<ChargeDataMessage>) => {
      const { data } = event;
      const isValid =
        data.messageType === "CHARGE_DATA_READY" &&
        data.paymentRequestId === paymentResponse?.linkId;
      if (
        event.origin === paymentPortalOrigins[region][env] &&
        paymentResponse &&
        isValid
      ) {
        onChange({
          ...paymentResponse,
          chargeRequest: data.payload.chargeRequest,
        });
        onBlur();
      }
    };
    window.addEventListener("message", listener);

    return () => window.removeEventListener("message", listener);
  }, [paymentResponse]);

  if (isSetupIncomplete) return null;

  const isLoading = loading || !paymentResponse?.paymentPortalUrl;

  return (
    <FieldWrapper aria-disabled={disabled}>
      <Label {...field} index={index} hideLabel={hideLabel} />
      <PaymentPortalWrapper hasError={!!error}>
        {value?.chargeRequest ? (
          <div className="payment-saved">
            <Icon library="lucide" name="Check" />
            Payment Saved
          </div>
        ) : (
          <PaymentPortal
            title="Payment Portal"
            src={paymentResponse?.paymentPortalUrl}
          />
        )}
      </PaymentPortalWrapper>
      <ErrorDisplay uri={field.uri} index={index} error={error} />
      {isLoading && <Loading />}
    </FieldWrapper>
  );
};
